/* Section 2 */

import React from 'react';
import { Col, Row } from 'antd';
import './css/LP_Section2.css';

const Section2 = ({ handleOpenPopup }) => {
  return (
    <section className="section2" id='section2'>
    <h1>Nous tokenisons, vous investissez</h1>
    <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
        <div className="section2_liste">
            <h2>01</h2>
            <p>Nous convertissons les droits de propriété d'œuvres d'art, d'objets de collection, de start-ups et de tout autre actif réel en tokens numériques</p>
        </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
        <div className="section2_liste">
            <h2>02</h2>
            <p>Cette action nous permet de mettre à disposition des parts de ces actifs réels avec une liquidité améliorée, une sécurité renforcée et une transparence totale</p>
        </div> {/*Nous permettant de vous*/ }
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
        <div className="section2_liste">
            <h2>03</h2>
            <p>Vous pouvez acquérir ces "parts" appelés tokens et ainsi bénéficier des avantages et / ou des revenus générés par ces actifs (gain en capital, dividendes, loyers...)</p>
        </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
        <div className="section2_liste">
            <h2>04</h2>
            <p>Vous pouvez ensuite vendre ces tokens sur la plateforme ou accroître votre position grâce à des transactions simples avec d'autres acheteurs / vendeurs</p>
        </div>
        </Col>
    </Row>
    <Row>
        <Col xs={6} sm={8} md={8} lg={8} xl={10}/>
        <Col xs={12} sm={8} md={8} lg={8} xl={4}>
            <li className="button_openPopUp" onClick={handleOpenPopup}>En découvrir davantage</li>
        </Col>
    </Row>
    </section>
  );
}

export default Section2;
