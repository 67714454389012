// AuthPopup.js

import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './ResetPasswordPopUp.css';
import { Carousel, Row, Col, Spin } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, XOutlined } from '@ant-design/icons';

import Collection from './optimized_media/obj_collection_w_back.avif';
import Luxe from './optimized_media/obj_luxe_w_back.avif';
import Art from './optimized_media/obj_art_w_back.avif';
import Startup from './optimized_media/obj_startups_w_back.avif';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = ({ onClose }) => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // État pour le statut de chargement
  const popupRef = useRef();
  const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 

  //------------------------------------------------------------------------------------------------------------//
  /* Reset password page */
  //------------------------------------------------------------------------------------------------------------//
    const query = useQuery();
    const token = query.get('token');

    const handleResetPassword = () => {
      setLoading(true);  // Début du chargement
      fetch('https://stratup1.azurewebsites.net/reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, password: password }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);  // Fin du chargement
          if (data.message) {
            setMessage(<span className="email-message2">{data.message}</span>);
            setTimeout(() => {
              window.location.href = "/?openPopUp=true";
            }, 3000);
          } else {
            setMessage(<span className="email-error2">{data.error}</span>);
          }
        })
        .catch((error) => {
          setLoading(false);  // Fin du chargement
          console.error('Erreur lors de la réinitialisation du mot de passe:', error);
          setMessage(<span className="email-error2">Erreur lors de la réinitialisation du mot de passe.</span>);
        });
    };

  //------------------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------------------//

  return (
    <Row className="resetPassword-popup" ref={popupRef}>
      <Col xs={4} sm={4} md={2} lg={5} xl={5} />
      <Col className="popup-content" xs={16} sm={16} md={18} lg={14} xl={14}>
        <Col xs={2} sm={2} md={1} lg={1} xl={1} />
        <Col xs={20} sm={18} md={12} lg={12} xl={12} className="popup-form">
          <h1>Entrez votre nouveau mot de passe:</h1>
          <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button className="button_signUp" id="submitBtn" onClick={handleResetPassword}>
            Réinitialiser le mot de passe
          </button>
          <p>{loading ? <Spin /> : message}</p>
          <h2>Suivez-nous !</h2>
          <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <LinkedinOutlined />
          </a>
          <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <FacebookOutlined />
          </a>
          <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <InstagramOutlined />
          </a>
          <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>
            <XOutlined />
          </a>
        </Col>
        <Col span={1}>{isSmallScreen && <div className="close-button" onClick={onClose}>X</div>}</Col>
        <Col xs={0} sm={0} md={13} lg={10} xl={10}>
          <Carousel autoplay className="image-slider">
            <img className="image" src={Collection} alt="Collection" />
            <img className="image" src={Luxe} alt="Luxe" />
            <img className="image" src={Art} alt="Art" />
            <img className="image" src={Startup} alt="Startup" />
          </Carousel>
        </Col>
      </Col>
      <Col xs={4} sm={4} md={5} lg={5} xl={5} />
    </Row>
  );
};

export default ResetPassword;
