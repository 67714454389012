// Header.js
import React, { useState, useEffect, useContext } from 'react';
import { Menu } from 'antd';
import { MenuOutlined} from '@ant-design/icons';

import { AuthContext } from '../AuthContext/AuthContext';

import './css/Header.css';

//import Logo from '../../optimized_media/Logo.png';
// import LogoWEBP from '../../optimized_media/Logo.webp';
import LogoAVIF from '../../optimized_media/Logo.avif';

const Header = ({ handleOpenPopup, handleClosePopup, isLandingPage }) => {

  // Détermine si l'écran est de 576px ou inférieur
  const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 
  // Utilisez le contexte
  const { isAuthenticated } = useContext(AuthContext);
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const headerElement = document.querySelector('.menu');

  const handleClickOutside = (event) => {
    if (headerElement && !headerElement.contains(event.target)) {
      setStateOpenKeys([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [stateOpenKeys]);

  const onOpenChange = (openKeys)  => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      setStateOpenKeys(['Menu'])
    } else {
      // close
      setStateOpenKeys([])
    }
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(<span className="menu-item-text menu" style={{width:'100%'}}> <MenuOutlined style={{color:'#004343', fontSize:'6vw'}} /></span>, 'Menu', null, [
      getItem(
        <span 
          className="menu-item-text-2" 
          onClick={() => {
            scrollToSection('section2');
            setStateOpenKeys([]);
            handleClosePopup()
          }}
          >Comment ça marche ?
        </span>
      , 'S2'),
      getItem(<span className="menu-item-text-2" onClick={() => scrollToSection('section3')}>Nos produits</span>, 'S3'),
      getItem(<span className="menu-item-text-2" onClick={() => scrollToSection('section4')}>Contacts</span>, 'S4'),
      getItem(<span className="menu-item-text-3" onClick={() => {handleOpenPopup(); setStateOpenKeys([]);}}>{isAuthenticated ? "Mon compte" : "Se connecter / S'inscrire"}</span>, 'Login'),
    ]),
  ];


  
  const scrollToSection = (sectionId) => {
    if (isLandingPage !==1) {
      window.location.href=`/#${sectionId}`
    }
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setStateOpenKeys([]);
    handleClosePopup();
  };
  

  return (
    <header 
    className= {isSmallScreen ? 'header-menu0':'header-menu1'}
     style={{
      position: isLandingPage === 1 || isSmallScreen ? 'fixed' : 'absolute'
    }}>
        {/* <picture className='logo-container'>
          <source srcSet={LogoAVIF} type="image/avif" />
          <source srcSet={LogoWEBP} type="image/webp" /> */}
          <img className="logo" 
            onClick={() => 
              isLandingPage ===1 ? 
                scrollToSection('section1')
                : window.location.href='/'
            } 
            src={LogoAVIF}
            alt="Logo"
          />
        {/* </picture> */}
          {isSmallScreen ?
            <Menu className="menu"
              mode="inline"
              items={items}
              expandIcon={null}
              openKeys={stateOpenKeys}
              onOpenChange={onOpenChange}
            />
          :
            <ul className='header-menu'>
                <li onClick={() => scrollToSection('section2')}>Comment ça marche ?</li>
                <li onClick={() => scrollToSection('section3')}>Nos produits</li>
                <li onClick={() => scrollToSection('section4')}>Contact</li>
                <li className="button_openPopUp" onClick={handleOpenPopup}>{isAuthenticated ? "Mon compte" : "Se connecter / S'inscrire"}</li>
              </ul>
          }
    </header>
);
}
export default Header;