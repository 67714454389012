import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const checkTokenExpiration = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000 - 60000; // 1 minute avant l'expiration pour prendre en compte le délai de déconnexion

      if (Date.now() >= expirationTime) {
        logout();
      } else {
        setTimeout(() => {
          logout();
        }, expirationTime - Date.now());
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000 - 60000; // 1 minute avant l'expiration

      if (Date.now() < expirationTime) {
        setIsAuthenticated(true);
        setUserEmail(decodedToken.sub.email); // Assume email is in the token
        setTimeout(() => {
          logout();
        }, expirationTime - Date.now());
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    } else {
      setIsAuthenticated(false);
    }

    const handleStorageChange = (event) => {
      if (event.key === 'token' && !event.newValue) {
        // Token was removed
        logout();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    let previousToken = localStorage.getItem('token');

    const checkLocalStorage = () => {
      const currentToken = localStorage.getItem('token');
      if (previousToken && !currentToken) {
        // Token was removed
        logout();
      }
      previousToken = currentToken;
    };

    const intervalId = setInterval(checkLocalStorage, 1000); // Vérifie toutes les secondes

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(intervalId);
    };
  }, [checkTokenExpiration]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    const decodedToken = jwtDecode(token);
    setUserEmail(decodedToken.sub.email); // Assume email is in the token
    checkTokenExpiration();
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserEmail(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userEmail, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
