import React, { useState } from 'react';
import './css/Footer.css'; // Assurez-vous de créer un fichier CSS séparé pour votre style
import { Row, Col } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, XOutlined} from '@ant-design/icons';


const Footer = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const isValidEmail = (email) => {
        // Expression régulière pour vérifier si l'e-mail est valide
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSignIn = () => {
        if (!email.trim() || !isValidEmail(email)) {
            setMessage(<span className="email-error">Veuillez entrer une adresse e-mail valide.</span>);
            return;
        }
        // Envoyer l'adresse e-mail au backend Flask
        fetch('https://stratup1.azurewebsites.net/submit_form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
        .then(response => {
            if (response.ok) {
                setEmail(''); // Réinitialiser le champ email après l'envoi réussi
                // Changer le texte et la couleur du bouton pendant 5 secondes
                setMessage(<span className="email-message">Créons ensemble un avenir financier sans contraintes !</span>);
                const button = document.getElementById('submitBtn');
                button.innerText = 'Inscription réussie!';
                button.style.backgroundColor = 'rgb(0, 67, 67)'; // Changer la couleur du bouton
                setTimeout(() => {
                    setMessage('');
                    button.innerText = 'Rester connecté !';
                    button.style.backgroundColor = ''; // Réinitialiser la couleur du bouton

                }, 5000);
            } else {
                console.error('Erreur lors de l\'envoi de l\'adresse email au backend Flask.');
            }
        })
        .catch(error => {
            console.error('Erreur lors de l\'envoi de l\'adresse email au backend Flask : ', error);
        });
    }

    return (
        <section id="section4" className="section4">
            <Row>
                <Col span={1}/>
                <Col className='S4_action' xs={22} sm={22} md={22} lg={22} xl={9}>
                    <Row>
                        <Col span={1}/>
                        <Col span={22}>
                            <h1>Notre projet vous intéresse ?</h1>
                            <input className='S4_email' type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <button className="S4_button_signUp" id="submitBtn" onClick={handleSignIn}>Rester connecté !</button>
                            <p>{message}</p> 
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={1}/>
                <Col className='S4' xs={22} sm={10} md={10} lg={10} xl={5}>
                    <h1>Des questions ?</h1>
                    <h2>Contactez-nous</h2>
                    <p>stratup.contact@gmail.com</p>
                        {/* +33 X XX XX XX XX <br/>
                        +33 X XX XX XX XX <br/> */}
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={1}/>
                <Col className='S4' xs={22} sm={10} md={10} lg={10} xl={7}>
                    <h1>Suivez-nous</h1>
                    <a href="https://www.linkedin.com/in/stratup/" target="_blank" rel="noopener noreferrer" style={{color:'#004343'}}><LinkedinOutlined/></a>
                    <a href="https://www.facebook.com/stratupFR/" target="_blank" rel="noopener noreferrer" style={{color:'#004343'}}><FacebookOutlined/></a>
                    <a href="https://www.instagram.com/stratupfr/" target="_blank" rel="noopener noreferrer" style={{color:'#004343'}}><InstagramOutlined /></a>
                    <a href="https://twitter.com/StratupFR" target="_blank" rel="noopener noreferrer"  style={{color:'#004343'}}><XOutlined /></a>
                </Col>
            </Row>
        </section>
    );
};

export default Footer;